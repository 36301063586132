<template>
  <ul :id="'children-'+parent">
    <li v-for="node in nodes" :id="'node-'+node.hashid">
        <b-card class="relative text-left mb-0 item organisation-objective" v-if="node.type=='Organisation'">
          <div class="mb-0 w-100">
            <div class="goal-header text-center d-flex align-items-center justify-content-between">
              <h5 class="mb-0 obje-name font-weight-bolder mr-2" v-b-tooltip.hover.v-default :title="node.title">{{node.title}}</h5>
              <div class="circle_percent" :data-percent="node.progress">
                <div class="circle_inner">
                  <div class="round_per"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="obj-period d-flex align-ites-center">
            <div class="quarter-data">
              <h6 class="mb-0 text-left">Period</h6>
              <p class="value text-left mb-0">{{node.period}}</p>
            </div>
          </div>
          <b-button :id="'node-'+node.hashid+'-node-arrow'" class="expand-icon" @click="listClicked(node);">
            <feather-icon icon="PlusIcon" size="20" class="before-expand" />
            <feather-icon icon="MinusIcon" size="20" class="after-expand" />
          </b-button>
        </b-card>
        <b-card class="relative text-left mb-0 item team-objective" v-if="node.type=='Team'">
          <div class="mb-0 w-100">
            <b-badge variant="info" class="badge team-badge"> Team </b-badge>
            <div class="goal-header text-center d-flex align-items-center justify-content-between">
              <h5 class="mb-0 obje-name font-weight-bolder mr-2" v-b-tooltip.hover.v-default :title="node.title">{{node.title}}</h5>
              <div class="circle_percent" :data-percent="node.progress">
                <div class="circle_inner">
                  <div class="round_per"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center objective-objtype">
            <div class="obj-name d-flex align-ites-center">
              <div class="quarter-data">
                <h6 class="mb-0 text-left">Team</h6>
                <p class="value text-left mb-0" v-b-tooltip.hover.v-default :title="node.team_name">{{node.team_name}}</p>
              </div>
            </div>
            <div class="obj-period d-flex align-ites-center">
              <div class="quarter-data">
                <h6 class="mb-0 text-left">Period</h6>
                <p class="value text-left mb-0">{{node.period}}</p>
              </div>
            </div>
          </div>
          <b-button :id="'node-'+node.hashid+'-node-arrow'" class="expand-icon" @click="listClicked(node);">
            <feather-icon icon="PlusIcon" size="20" class="before-expand" />
            <feather-icon icon="MinusIcon" size="20" class="after-expand" />
          </b-button>
        </b-card>
        <b-card class="relative text-left mb-0 item indivdual-objective" v-if="node.type=='Individual'">
          <div class="mb-0 w-100">
            <b-badge variant="info" class="badge individual-badge"> Individual </b-badge>
            <div class="goal-header text-center d-flex align-items-center justify-content-between">
              <h5 class="mb-0 obje-name font-weight-bolder mr-2" v-b-tooltip.hover.v-default :title="node.title">{{node.title}}</h5>
              <div class="circle_percent" :data-percent="node.progress">
                <div class="circle_inner">
                  <div class="round_per"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center objective-objtype">
            <div class="obj-name d-flex align-ites-center">
              <div class="quarter-data">
                <h6 class="mb-0 text-left">Name</h6>
                <p class="value text-left mb-0" v-b-tooltip.hover.v-default :title="node.user_name">{{node.user_name}}</p>
              </div>
            </div>
            <div class="obj-period d-flex align-ites-center">
              <div class="quarter-data">
                <h6 class="mb-0 text-left">Period</h6>
                <p class="value text-left mb-0">{{node.period}}</p>
              </div>
            </div>
          </div>
          <b-button :id="'node-'+node.hashid+'-node-arrow'" class="expand-icon" @click="listClicked(node);">
            <feather-icon icon="PlusIcon" size="20" class="before-expand" />
            <feather-icon icon="MinusIcon" size="20" class="after-expand" />
          </b-button>
        </b-card>
        <nodes v-if="node.children && node.children.length" :nodes="node.children" :parent="node.hashid" :ref="'children-'+node.hashid"></nodes>
        <!-- <div>
        <ul class="team-objective" style="display: flex">
          <li>
            <b-card class="relative mb-0">
              <div class="mb-0 w-100">
                <b-badge variant="info" class="badge team-badge"> Team </b-badge>
                <div class="goal-header text-center d-flex align-items-center justify-content-between">
                  <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                  <div class="circle_percent" data-percent="20">
                    <div class="circle_inner">
                      <div class="round_per"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center objective-objtype">
                <div class="obj-name d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Team</h6>
                    <p class="value text-left mb-0">Zircly</p>
                  </div>
                </div>
                <div class="obj-period d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Period</h6>
                    <p class="value text-left mb-0">Q6</p>
                  </div>
                </div>
              </div>
              <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
            </b-card>
            <div>
              <ul class="third-node" style="display: block">
                <li>
                  <b-card class="relative mb-0">
                    <div class="mb-0 w-100">
                      <b-badge variant="info" class="badge individual-badge"> Individual </b-badge>
                      <div class="goal-header text-center d-flex align-items-center justify-content-between">
                        <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                        <div class="circle_percent" data-percent="20">
                          <div class="circle_inner">
                            <div class="round_per"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex align-items-center objective-objtype">
                      <div class="obj-name d-flex align-ites-center">
                        <div class="quarter-data">
                          <h6 class="mb-0 text-left">Name</h6>
                          <p class="value text-left mb-0">Vignesvaran B</p>
                        </div>
                      </div>
                      <div class="obj-period d-flex align-ites-center">
                        <div class="quarter-data">
                          <h6 class="mb-0 text-left">Period</h6>
                          <p class="value text-left mb-0">Q6</p>
                        </div>
                      </div>
                    </div>
                    <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
                  </b-card>
                </li>
                <li>
                  <b-card class="relative mb-0">
                    <div class="mb-0 w-100">
                      <b-badge variant="info" class="badge individual-badge"> Individual </b-badge>
                      <div class="goal-header text-center d-flex align-items-center justify-content-between">
                        <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                        <div class="circle_percent" data-percent="20">
                          <div class="circle_inner">
                            <div class="round_per"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex align-items-center objective-objtype">
                      <div class="obj-name d-flex align-ites-center">
                        <div class="quarter-data">
                          <h6 class="mb-0 text-left">Name</h6>
                          <p class="value text-left mb-0">Vignesvaran B</p>
                        </div>
                      </div>
                      <div class="obj-period d-flex align-ites-center">
                        <div class="quarter-data">
                          <h6 class="mb-0 text-left">Period</h6>
                          <p class="value text-left mb-0">Q6</p>
                        </div>
                      </div>
                    </div>
                    <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
                  </b-card>
                </li>
                <li>
                  <b-card class="relative mb-0">
                    <div class="mb-0 w-100">
                      <b-badge variant="info" class="badge individual-badge"> Individual </b-badge>
                      <div class="goal-header text-center d-flex align-items-center justify-content-between">
                        <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                        <div class="circle_percent" data-percent="20">
                          <div class="circle_inner">
                            <div class="round_per"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex align-items-center objective-objtype">
                      <div class="obj-name d-flex align-ites-center">
                        <div class="quarter-data">
                          <h6 class="mb-0 text-left">Name</h6>
                          <p class="value text-left mb-0">Vignesvaran B</p>
                        </div>
                      </div>
                      <div class="obj-period d-flex align-ites-center">
                        <div class="quarter-data">
                          <h6 class="mb-0 text-left">Period</h6>
                          <p class="value text-left mb-0">Q6</p>
                        </div>
                      </div>
                    </div>
                    <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
                  </b-card>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <b-card class="relative mb-0">
              <div class="mb-0 w-100">
                <b-badge variant="info" class="badge team-badge"> Team </b-badge>
                <div class="goal-header text-center d-flex align-items-center justify-content-between">
                  <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                  <div class="circle_percent" data-percent="40">
                    <div class="circle_inner">
                      <div class="round_per"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center objective-objtype">
                <div class="obj-name d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Team</h6>
                    <p class="value text-left mb-0">Klizer</p>
                  </div>
                </div>
                <div class="obj-period d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Period</h6>
                    <p class="value text-left mb-0">Q1</p>
                  </div>
                </div>
              </div>
              <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
            </b-card>
          </li>
          <li>
            <b-card class="relative mb-0">
              <div class="mb-0 w-100">
                <b-badge variant="info" class="badge team-badge"> Team </b-badge>
                <div class="goal-header text-center d-flex align-items-center justify-content-between">
                  <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                  <div class="circle_percent" data-percent="10">
                    <div class="circle_inner">
                      <div class="round_per"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center objective-objtype">
                <div class="obj-name d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Team</h6>
                    <p class="value text-left mb-0">Integrator</p>
                  </div>
                </div>
                <div class="obj-period d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Period</h6>
                    <p class="value text-left mb-0">Q6</p>
                  </div>
                </div>
              </div>
              <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
            </b-card>
          </li>
          <li>
            <b-card class="relative mb-0">
              <div class="mb-0 w-100">
                <b-badge variant="info" class="badge team-badge"> Team </b-badge>
                <div class="goal-header text-center d-flex align-items-center justify-content-between">
                  <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                  <div class="circle_percent" data-percent="5">
                    <div class="circle_inner">
                      <div class="round_per"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center objective-objtype">
                <div class="obj-name d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Team</h6>
                    <p class="value text-left mb-0">PIM</p>
                  </div>
                </div>
                <div class="obj-period d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Period</h6>
                    <p class="value text-left mb-0">Q1</p>
                  </div>
                </div>
              </div>
              <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
            </b-card>
          </li>
          <li>
            <b-card class="relative mb-0">
              <div class="mb-0 w-100">
                <b-badge variant="info" class="badge team-badge"> Team </b-badge>
                <div class="goal-header text-center d-flex align-items-center justify-content-between">
                  <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                  <div class="circle_percent" data-percent="50">
                    <div class="circle_inner">
                      <div class="round_per"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center objective-objtype">
                <div class="obj-name d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Team</h6>
                    <p class="value text-left mb-0">QA Touch</p>
                  </div>
                </div>
                <div class="obj-period d-flex align-ites-center">
                  <div class="quarter-data">
                    <h6 class="mb-0 text-left">Period</h6>
                    <p class="value text-left mb-0">Q6</p>
                  </div>
                </div>
              </div>
              <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
            </b-card>
          </li>
        </ul>
        </div> -->
      
    </li>
    <!-- <li>
      <b-card class="relative mb-0">
                    <div class="mb-0 w-100">
                      <div class="goal-header text-center d-flex align-items-center justify-content-between">
                        <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                        <div class="circle_percent" data-percent="50">
                          <div class="circle_inner">
                            <div class="round_per"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="obj-period d-flex align-ites-center">
                      <div class="quarter-data">
                        <h6 class="mb-0 text-left">Period</h6>
                        <p class="value text-left mb-0">Q6</p>
                      </div>
                    </div>
        <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
      </b-card>
    </li>
    <li>
      <b-card class="relative mb-0">
                    <div class="mb-0 w-100">
                      <div class="goal-header text-center d-flex align-items-center justify-content-between">
                        <h5 class="mb-0 obje-name font-weight-bolder mr-2">Revenue for Q1, 2024</h5>
                        <div class="circle_percent" data-percent="50">
                          <div class="circle_inner">
                            <div class="round_per"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="obj-period d-flex align-ites-center">
                      <div class="quarter-data">
                        <h6 class="mb-0 text-left">Period</h6>
                        <p class="value text-left mb-0">Q6</p>
                      </div>
                    </div>
        <b-button class="expand-icon"><feather-icon icon="PlusIcon" size="18" class="text-primary font-weight-bolder m-0" /></b-button>
      </b-card>
    </li> -->
  </ul>
</template>

<script>
  import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import Nodes from "./nodes.vue";
import axios from "@axios";
import $ from "jquery";
export default {
  name:"nodes",
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    vSelect,
    Nodes
  },
  props: {
    nodes: {
      type: Array,
      required: false,
    },
    parent: {
      type: String,
      required: true,
    },
    selectedObjective:{
    }
  },
  directives: {
    Ripple,
  },
 mounted () {
    var nodeId = '#children-'+this.parent;
      $(nodeId).hide();
      $(nodeId+'.active').hide(); 
  },
  data() {
    return {
      objective:{}
    }
  },
  watch: {
    objective(val) {
      this.$emit('update:selectedObjective',val);
    },
  },
  methods: {
    expand(){
      // for (let index = 0; index < this.nodes.length; index++) {
      //   const element = this.nodes[index];
      //   var nodeId = '#children-'+element.hashid;
      //   $(nodeId).show();
      //   $(nodeId+'.active').show(); 
      //   // this.$refs['children-company'].expand();
      // }
    },
    getOKR(objective){
      axios
        .get("objectives/"+objective.hashid)
        .then((response) => {
          this.$emit('update:selectedObjective',response.data.data);
        });
    },
    listClicked(node){
      node.opened = true;
      var nodeId = '#node-'+node.hashid;
      $('#children-'+this.parent+" li .expand-icon").removeClass("activenode");
      $(nodeId).addClass("open-tree");      
      $(nodeId).siblings('li').removeClass('open-tree');
      $(nodeId).siblings('li').find('ul').hide();
      var children = $(nodeId).find('> ul');
      if (children.is(":visible")) {
        children.hide('fast').removeClass('active');
      }
      else {
        children.show('fast').addClass('active');
        $(nodeId+"-node-arrow").addClass("activenode");
      }  
    }
  },
  computed:{
  }
}
</script>